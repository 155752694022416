<template>
  <div class="flex-center full-screen" id="app">
    <div>
      <the-gratis style="height: 7vh;min-height:55px"></the-gratis>
    </div>
    <div class="caslon" style="font-size: 1.2em;margin-bottom:2vh">
      Deepdene
    </div>
    <transition
      name="fade"
      mode="out-in"
      appear
      @beforeLeave="beforeLeave"
      @enter="enter"
      @afterEnter="afterEnter"
    >
      <router-view />
    </transition>
    <div class="caslon alpha80" style="margin: 2vh">
      <a href="https://theGratis.com.au" target="_blank">theGratis.com.au</a>
    </div>
    <div
      @click="signOut"
      style="cursor:pointer;font-size: 7px;opacity: .5"
      v-if="user"
    >
      logout
    </div>
  </div>
</template>

<style lang="scss">
@import "~@/styles.scss";
</style>
<script>
import TheGratis from "@/svg/TheGratis";
import firebase from "firebase";

import router from "@/router";

export default {
  components: { TheGratis },
  created() {
    this.initFirebaseAuth();
  },
  data() {
    return {
      user: null
    };
  },
  methods: {
    initFirebaseAuth() {
      firebase.auth().onAuthStateChanged(this.authStateObserver);
    },
    authStateObserver(user) {
      // debugger; // eslint-disable-line no-debugger
      this.user = user;
      console.log(user);

      if (user) {
        window.regName = user.displayName;
        router.push({ name: "ThankYou" });
      } else {
        router.push({ name: "Home" });
      }
    },
    signOut: function() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.user = null;
        })
        .catch(err => console.log(err));
    },
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    }
  }
};
</script>
