<template>
  <div style="position: relative">
    <div ref="submitting" class="full-size origin hidden" style="opacity: .9">
      <div class="full-size flex-center">Submitting Your Request...</div>
    </div>
    <div ref="submitError" class="full-size origin hidden">
      <div class="full-size flex-center">
        <div>Something went wrong. Please try again later.</div>
        <br />
        <button @click="submitErrorClick">OK</button>
      </div>
    </div>
    <form id="hive-form" ref="form">
      <input
        v-model="name"
        type="text"
        id="name"
        name="name"
        placeholder="Name*"
        class="content-font"
      />
      <div
        style="height:20px"
        class="flex-center error-msg"
        id="error-name"
      ></div>
      <input
        type="email"
        id="email"
        name="email"
        placeholder="Email*"
        class="content-font"
      />
      <div
        style="height:20px"
        class="flex-center error-msg"
        id="error-email"
      ></div>
      <input
        type="text"
        id="phone"
        name="phone"
        placeholder="Phone*"
        class="content-font"
      />
      <div
        style="height:20px"
        class="flex-center error-msg"
        id="error-phone"
      ></div>

      <input
        type="text"
        id="postcode"
        name="post"
        placeholder="Postcode*"
        class="content-font"
        digits="true"
      />
      <div
        style="height:20px"
        class="flex-center error-msg"
        id="error-post"
      ></div>

      <textarea
        name="message"
        class="content-font"
        placeholder="Message"
      ></textarea>

      <div
        style="width:100%;display:inline-block;text-align: right;margin-top:20px"
      >
        <button class="submit-button" id="submit-button">Submit</button>
      </div>
    </form>
  </div>
</template>

<script>
import HiveFader from "@/HiveFader";
import router from "@/router";

export default {
  name: "RegisterForm",
  data() {
    return {
      name: null,
      faderSubmitting: null,
      faderError: null,
      faderForm: null
    };
  },
  methods: {
    submitErrorClick() {
      // this.submitError = false;
      this.faderForm.in();
      this.faderError.out();
    },
    submitStart() {
      // console.log("submitting....");
      this.faderForm.out();
      this.faderSubmitting.in();
    },
    submitSuccessful() {
      this.faderSubmitting.out();
      router.push({ name: "ThankYou" });
      window.regName = this.name;
    },
    submitError() {
      this.faderSubmitting.out();
      this.faderError.in();
    }
  },

  mounted() {
    // Hivue.log(this.$refs);
    window.HIVE_LEAD_FORM(
      "THEGRATIS",
      "hive-form",
      { submitButtonId: "submit-button" },
      {
        // @submit-start="submitting=true" @submit-error="submitError=true" @submit-successful="submitDone=true"
        start: this.submitStart,
        successful: this.submitSuccessful,
        error: this.submitError
      }
    );
    this.faderForm = new HiveFader(this.$refs.form, {
      yOffset: "1vh",
      hideInitially: false
    });

    this.faderSubmitting = new HiveFader(this.$refs.submitting, {
      yOffset: "1vh"
    });
    this.faderError = new HiveFader(this.$refs.submitError, {
      yOffset: "1vh"
    });
  }
};
</script>

<style lang="scss" scoped>
@use "~@/styles.scss";
.error-msg {
  color: #e57c7c;
}

input,
textarea {
  outline: none;
  border: none;
  border-bottom: 1px solid styles.$navy;
  /*font-size: 1.2em;*/
  /*height: 40px;*/
  padding: 5px;
  /*font-family: Poppins, sans-serif;*/
  background: none;
  /* margin-bottom: 5vh; */
  transition: all 1s;
  display: block;
  margin: auto;
  color: styles.$navy;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  font-family: big-caslon-fb, serif;
  margin-bottom: 1px;
  font-weight: bolder;
  opacity: 0.8;
}

textarea {
  height: 100px;
  /* min-height: 100px; */
}

button {
  background: none;
  border: 1px solid styles.$navy;
  transition: all 1s;
  color: styles.$navy;
  padding: 5px;
  font-family: big-caslon-fb, serif;
}

.submit-button {
  width: 30%;
  /* max-width: 400px; */
  min-width: 100px;
  height: 3vh;
  min-height: 40px;
}

button:hover:enabled {
  background-color: styles.$navy;
  color: styles.$warm-white;
}

button:disabled {
  opacity: 0.5;
}

input:focus,
textarea:focus {
  border-bottom: 2px solid styles.$navy;
  color: styles.$navy;
  margin-bottom: 0px;
  opacity: 1;
}

.text-peach {
  color: #ddbcb0 !important;
}

.error {
  color: #e57c7c;
  display: block;
}

.content-width {
  width: 100%;
  max-width: 600px;
}

.error-wrapper {
  height: 1.5vh;
  max-height: 100px;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 0.4;
  color: styles.$navy-dark;
}

/* Firefox < 19 */

input:-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 0.4;
  color: styles.$navy-dark;
}

/* Firefox > 19 */

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 0.4;
  color: styles.$navy-dark;
}

/* Internet Explorer 10 */

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 0.4;
  color: styles.$navy-dark;
}
input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  opacity: 0.2;
  color: styles.$navy-dark;
}

/* Firefox < 19 */

input:focus:-moz-placeholder,
textarea:focus:-moz-placeholder {
  opacity: 0.2;
  color: styles.$navy-dark;
}

/* Firefox > 19 */

input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
  opacity: 0.2;
  color: styles.$navy-dark;
}

/* Internet Explorer 10 */

input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
  opacity: 0.2;
  color: styles.$navy-dark;
}
</style>
