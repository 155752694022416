<template>
  <register-form
    style="width:90%;max-width: 400px;margin:auto;margin-top:2vh;margin-bottom:2vh"
  ></register-form>
</template>

<script>
import RegisterForm from "@/components/RegisterForm";
export default {
  name: "Register",
  components: { RegisterForm }
};
</script>

<style scoped></style>
