<template>
  <div>
    <!--    <div><the-gratis style="height: 7vh;min-height:55px"></the-gratis></div>-->
    <!--    <div class="caslon" style="font-size: 1.2em;margin-bottom:2vh">-->
    <!--      Deepdene-->
    <!--    </div>-->
    <!--    The home page-->
    <div>
      <div class="text-block">
        Book your private session to tour our completed ground floor residence.
      </div>
      <div v-if="this.regMethod === null">
        <img
          class="avatar"
          src="@/assets/nichole.jpg"
          style="margin: 1vh"
          alt="Nichole Pickering"
        />
        <div class="text-block poppins">
          <span class="font-smaller">Nichole Pickering</span>
          <br /><a href="tel:+61-448-49-48-48">0448 49 48 48</a>
        </div>
        <div class="text-block italic font-smaller">
          or
        </div>
        <div class="text-block">
          Register to view our brochures.
        </div>
        <div style="height:1vh"></div>
        <div class="sign-btn email-btn" @click="regEmail">
          <email class="alpha80"></email> Register With Email
        </div>
        <!--        <div @click="signInWithApple" class="sign-btn apple-btn">-->
        <!--          <apple></apple> Continue With Apple-->
        <!--        </div>-->
        <div @click="signInWithFacebook" class="sign-btn facebook-btn">
          <facebook></facebook> Continue With Facebook
        </div>
        <div @click="signInWithGoogle" class="sign-btn google-btn">
          <google></google> Continue With Google
        </div>
      </div>
    </div>

    <!--    &lt;!&ndash;    The more info page&ndash;&gt;-->
    <!--    <div v-if="false">-->
    <!--      <div class="text-block">-->
    <!--        Hi {name}, would you please provide more information?-->
    <!--      </div>-->
    <!--      <div style="width:250px;margin:auto;margin-top:2vh;margin-bottom: 3vh">-->
    <!--        <input-->
    <!--          type="text"-->
    <!--          id="phone"-->
    <!--          name="phone"-->
    <!--          placeholder="Phone Number"-->
    <!--          class="content-font"-->
    <!--        />-->
    <!--        <input-->
    <!--          type="text"-->
    <!--          id="post"-->
    <!--          name="post"-->
    <!--          placeholder="Postcode"-->
    <!--          class="content-font"-->
    <!--        />-->
    <!--        <textarea-->
    <!--          name="message"-->
    <!--          class="content-font"-->
    <!--          placeholder="Drop us a line"-->
    <!--        ></textarea>-->
    <!--        <div style="text-align: right;margin-top:1vh">-->
    <!--          <button class="submit-button" id="submit-button">-->
    <!--            Proceed to brochures-->
    <!--          </button>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <!--    <div class="caslon alpha80" style="margin: 2vh">theGratis.com.au</div>-->
  </div>
</template>

<script>
import firebase from "firebase";
// import TheGratis from "@/svg/TheGratis";
// import RegisterForm from "@/components/RegisterForm";
import Facebook from "@/svg/Facebook";
import Google from "@/svg/Google";
// import Apple from "@/svg/Apple";
import Email from "@/svg/Email";
import router from "@/router";
import { api } from "@/api";

firebase.initializeApp(require("../firebase.json"));
export default {
  name: "Home",
  components: { Email, Google, Facebook },
  data() {
    return {
      regMethod: null
    };
  },
  created() {
    this.initFirebaseAuth();
  },

  methods: {
    initFirebaseAuth() {
      firebase.auth().onAuthStateChanged(this.authStateObserver);
    },
    async authStateObserver(user) {
      console.log("user", user);
      // debugger; // eslint-disable-line no-debugger
      this.user = user;
      if (user) {
        // router.push({ name: "ThankYou" });
        // console.log("lets call leadsRegisterByProviders here.");
        console.log("calling leadsRegisterWith data", user.uid);
        const data = {
          uid: user.uid,
          project: "THEGRATIS"
        };
        if (window.hiveuid) data.uuid = window.hiveuid;
        let resp = await api.general.leadsRegisterWithProviders(data);
        console.log("Response", resp);
      } else {
        // router.push({ name: "Home" });
      }
    },
    regEmail() {
      // this.regMethod = "email";
      router.push({ name: "Register" });
    },
    signInWithFacebook: function() {
      const provider = new firebase.auth.FacebookAuthProvider();
      provider.addScope("email");
      provider.addScope("public_profile");
      // provider.addScope("gender");
      // provider.addScope("user_birthday");
      firebase.auth().signInWithPopup(provider);
    },
    signInWithGoogle: function() {
      const provider = new firebase.auth.GoogleAuthProvider();
      // provider.addScope(
      //   "https://www.googleapis.com/auth/user.phonenumbers.read"
      // );
      firebase.auth().signInWithPopup(provider);
    },
    signInWithApple: function() {
      const provider = new firebase.auth.OAuthProvider("apple.com");
      provider.addScope("email");
      provider.addScope("name");
      firebase.auth().signInWithPopup(provider);
    }
  }
};
</script>
<style lang="scss" scoped>
@use "~@/styles.scss";
.thumb {
  width: 40vw;
  max-width: 200px;
}
</style>
