<template>
  <div style="padding-top:2vh;padding-bottom: 2vh">
    <div class="text-block">
      Thank you {{ name }} for registering your interest. <br />
      Please find our brochures below.
    </div>
    <div style="display:flex;padding-top: 2vh;justify-content: space-around">
      <div>
        <a href="https://brochure.theGratis.com.au" target="_blank">
          <img src="@/assets/brochure.png" alt="Brochure Vol.1" class="thumb" />
        </a>
        <div class="caslon font-smaller">The Gratis Vol.1</div>
      </div>
      <div>
        <a href="https://brochure.theGratis.com.au/spec" target="_blank">
          <img
            src="@/assets/brochure-specs.png"
            alt="Brochure Vol.2"
            class="thumb"
          />
        </a>

        <div class="caslon font-smaller">
          The Gratis Vol.2<br />Specifications
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";

export default {
  name: "ThankYou",
  data() {
    return {
      name: window.regName
    };
  },
  methods: {
    signOut: function() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.user = null;
        })
        .catch(err => console.log(err));
    }
  }
};
</script>

<style scoped></style>
