// import HiveFullPage from "./HiveFullPage";

const Hivue = {
  log(...msg) {
    if (msg.length > 1) {
      let str = "";
      for (let i = 0; i < msg.length; i++) {
        if (i > 0) str += " | ";
        str += msg[i];
      }
      // eslint-disable-next-line no-console
      console.log(str);
    } else {
      // eslint-disable-next-line no-console
      console.log(msg[0]);
    }
  },
  err(msg) {
    // eslint-disable-next-line no-console
    console.error(msg);
  },
  error(msg) {
    Hivue.err(msg);
  },
  isVueElement(element) {
    return element.__vue__ !== null;
  },
  $el(element) {
    if (!element) {
      this.err("Hivue.$el getting null element.");
      return null;
    }
    if (element.$el) return element.$el;
    return element;
  },
  $vue(element) {
    return element.__vue__ || null;
  },
  pauseAllVideo(element) {
    const videos = element.querySelectorAll("video"); // get all videos using "video" css selector
    /*Loop*/
    for (let video of videos) {
      // use var video if "strict mode" is enabled
      video.pause();
    }
  },
  playAllVideo(element) {
    const videos = element.querySelectorAll("video"); // get all videos using "video" css selector
    /*Loop*/
    for (let video of videos) {
      // use var video if "strict mode" is enabled
      video.play();
    }
  },
  setStyle(element, obj) {
    // this.log(element);
    const el = Hivue.$el(element);
    for (const [key, value] of Object.entries(obj)) {
      el.style[key] = value;
    }
  },
  hasClass(element, c) {
    return element.classList.contains(c);
  },
  addClass(element, c) {
    element.classList.add(c);
  },
  removeClass(element, c) {
    // this.log("remove "+element);
    element.classList.remove(c);
  },
  callVueMethod(element, method) {
    if (element) {
      const vue = element.__vue__;
      if (vue) {
        const m = vue[method];
        if (m) m();
      }
    }
  },
  on(element, event, listener) {
    element.addEventListener(event, listener);
    return listener;
  },
  off(element, event, listener) {
    element.removeEventListener(event, listener);
  },
  getComputedStyle(element, value) {
    const s = window.getComputedStyle(Hivue.$el(element));
    return s.getPropertyValue(value);
  },
  localToGlobal(local, x, y) {
    const rect = local.getBoundingClientRect();
    return {
      x: x + rect.left,
      y: y + rect.top
    };
  },
  globalToLocal(local, x, y) {
    const rect = local.getBoundingClientRect();
    Hivue.log(rect);
    return {
      x: x - rect.left,
      y: y - rect.top
    };
  }
};
export { Hivue as default };
