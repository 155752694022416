<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 476 241"
    style="enable-background:new 0 0 476 241;"
    xml:space="preserve"
  >
    <g>
      <path
        fill="currentColor"
        d="M141.3,161.9c-1.5,2.3-1.5,8.2-1.5,13.2v24.1c-3.2,2.5-26.1,14-47.5,14c-42.1,0-67.1-23.1-67.1-60.7
		c0-11,3.7-29.7,19-44.3C54.9,97.9,70,91,92.2,91c26.8,0,40.8,11,41.8,11c0.3,0,2.7-3,5.5-6.8h1.5v35.9h-2
		c-2.3-9.7-7.8-19.1-13.3-24.9c-7.2-7.4-18.6-12.5-32.3-12.5c-29.5,0-47.6,27.9-47.6,59c0,34.3,17.1,57.7,46.8,57.7
		c10.5,0,20.5-3.5,25.8-11.5c1.8-2.8,2.7-11.2,2.7-18.8c0-7.7-0.3-14-4-18c-2.7-3-7.3-4.6-13.5-5.1v-1.6h48.8v1.6
		C148.5,157.6,143.3,158.7,141.3,161.9z"
      />
      <path
        fill="currentColor"
        d="M206.6,149.5c-7.3,0-7-5.9-13.2-5.9c-5.5,0-10.3,8.2-12.8,15V206c1.5,4.1,9.7,3.6,14.2,4.3v1.6h-37.3v-1.6
		c5.2-0.5,9.3-1.8,9.3-5.3v-55.7c0-1.6-0.2-2.5-1.7-3.5c-2-1.2-4.8-3-7.5-4.1v-1l21.8-8.4h1l-0.3,22.9h0.3
		c2.3-5.3,9.3-22.9,23.3-22.9c5.5,0,10,3.6,10,8.7C213.7,145.9,210.4,149.5,206.6,149.5z"
      />
      <path
        fill="currentColor"
        d="M274.8,212.5c-7.5,0-14.5-4.1-14.5-15.2l-0.3-0.2c-3.7,5.9-12.2,15-24,15c-7,0-15.5-4.3-15.5-15.7
		c0-6.1,5.7-12,13-16.6c14.7-9.2,22.1-12.5,26.8-15.3v-17c0-1-2.5-7.6-13-7.6c-7.2,0-14.2,5.8-14.2,8.4v7.4c0,3-4,5.9-7.7,5.9
		c-3.5,0-6.5-3-6.5-6.3c0-5.4,6.2-8.6,9.8-10.7c11.5-6.8,24.8-12.4,30.5-12.4c5.8,0,11.5,3.5,13.7,7.7c0.8,1.5,1,5.9,1,9.7
		c0,15.7-0.3,24.4-0.3,39.4c0,9.9,4.2,12.2,8.5,12.2c5.2,0,7.3-2.3,8.2-5.6h1C290.8,204.2,284.2,212.5,274.8,212.5z M260.3,167.1
		c-8,5.8-26.6,12.4-26.6,23.6c0,5.8,4.3,11.2,10.8,11.2c5.7,0,10.2-1.2,13.8-4.8c1.5-1.3,2-3,2-4.6V167.1z"
      />
      <path
        fill="currentColor"
        d="M340.3,144.1H315v43.4c0,11.2,5.3,16,13.2,16c6.5,0,10.3-2,13.5-6.4l1,0.5c-2.5,6.8-9.5,15.2-23.5,15.2
		c-9.2,0-18.1-7.3-18.1-14.3c0-20.6,0.3-39.9,0.3-54.2h-12.2v-1.8l24.1-22.6h1.3V134h30.8L340.3,144.1z"
      />
      <path
        fill="currentColor"
        d="M350.8,212v-1.6c5.2-0.5,11.3-1.2,11.3-5.3v-56.5c0-1.6-0.2-3.1-1.7-4.1c-2-1.3-4.8-2.8-8-3.8v-1l22.5-7.3h1
		V206c0.8,4.3,6.2,3.6,10.7,4.3v1.6H350.8z M368.2,115.2c-4.8,0-9-4.1-9-8.9c0-4.9,4-8.7,9-8.7c5,0,9,3.8,9,8.7
		C377.2,111.2,373.2,115.2,368.2,115.2z"
      />
      <path
        fill="currentColor"
        d="M420.3,212.6c-9.7,0-18.5-2.1-25.8-5.4v-21.8h1.3c3.7,10.5,9.8,24.9,24.5,24.9c10.7,0,17.5-5.9,17.5-18.5
		c0-6.8-4.7-11.5-12.2-13c-6-1.2-11.7-2.5-16.2-4c-8.3-2.8-14.2-10.4-14.2-20.1c0-13.5,12.3-22.4,26.8-22.4c9.3,0,16.2,1.8,22.5,4.3
		l2,20.4l-1.3,0.3c-6.2-13.4-12-22.7-24.5-22.7c-7.5,0-15.2,5.3-15.2,15.3c0,8.1,5.3,11,12.3,12.7l15.5,3.5c10,2.3,18,10.7,18,21.8
		C451.5,205,436.7,212.6,420.3,212.6z"
      />
    </g>
    <g>
      <path
        fill="currentColor"
        d="M186.9,86.1c2.3-0.2,4.2-0.6,5.7-1.1c1.4-0.5,2.7-1.3,3.7-2.3c0.2-0.2,0.5-0.6,0.7-1.1
		c0.3-0.6,0.6-1.2,0.9-1.9c0.3-0.7,0.6-1.5,0.9-2.3c0.3-0.8,0.6-1.6,0.9-2.3c0.8-2,1.5-4.1,2.3-6.3s1.5-4.2,2.1-6.2
		c0.7-1.9,1.2-3.6,1.7-5.1c0.5-1.5,0.8-2.5,1.1-3.2c0.4-1.2,0.9-2.6,1.4-4.2c0.6-1.6,1.2-3.4,1.8-5.3c0.6-1.9,1.3-3.9,2-5.9
		c0.7-2,1.3-4.1,2-6c0.6-2,1.3-3.9,1.8-5.7c0.6-1.8,1.1-3.4,1.5-4.8l-8.9-0.1c-0.9,0-1.9,0.2-3.1,0.8c-1.2,0.6-2.4,1.3-3.6,2.1
		c-1.2,0.8-2.5,1.8-3.7,2.9c-1.2,1.1-2.4,2.1-3.5,3.2c-1.1,1-2,2-2.9,2.9c-0.8,0.9-1.5,1.6-1.9,2.1l-0.8-0.4l5.1-14.8h58.1
		l-5.2,15.2H246c-0.1-0.7-0.2-1.6-0.3-2.6c-0.2-1-0.4-2-0.7-3c-0.3-1-0.6-2-0.9-3s-0.7-1.8-1.1-2.6c-0.4-0.8-0.8-1.4-1.2-1.8
		c-0.4-0.5-0.8-0.7-1.1-0.7l-12.3-0.1c-0.5,1.4-1,3-1.6,4.9c-0.6,1.9-1.3,3.9-2,6c-0.7,2.1-1.4,4.2-2.1,6.4c-0.7,2.2-1.4,4.2-2,6.1
		c-0.6,1.9-1.2,3.6-1.7,5.1c-0.5,1.5-0.9,2.6-1.2,3.4c-0.4,1.2-0.9,2.6-1.4,4.2s-1.1,3.2-1.6,5c-0.6,1.8-1.2,3.6-1.8,5.4
		c-0.6,1.8-1.2,3.7-1.8,5.4c-0.2,0.7-0.5,1.4-0.7,2.1c-0.2,0.7-0.4,1.5-0.7,2.2c-0.2,0.7-0.4,1.3-0.5,1.9c-0.1,0.6-0.2,1-0.2,1.4
		c0,0.7,0.3,1.2,0.8,1.7c0.5,0.5,1.2,0.9,1.9,1.2c0.8,0.3,1.6,0.6,2.5,0.8c0.9,0.2,1.8,0.3,2.6,0.4l-0.3,0.9h-29.9L186.9,86.1z"
      />
      <path
        fill="currentColor"
        d="M265.7,87.4c-1,0-1.9-0.3-2.6-0.8c-0.7-0.6-1-1.3-1.1-2.1c0-1.2,0.1-2.4,0.4-3.4c0.3-1.1,0.6-2.1,0.9-3
		c0.3-0.7,0.6-1.6,1.1-2.7c0.4-1.1,0.9-2.3,1.4-3.6c0.5-1.3,1.1-2.7,1.6-4.1c0.6-1.4,1.1-2.7,1.6-4c0.5-1.3,0.9-2.4,1.3-3.4
		c0.4-1,0.7-1.8,0.9-2.3c0.6-1.8,1.1-3.2,1.5-4.4c0.4-1.2,0.6-2.1,0.6-2.8c0-0.6-0.1-1-0.2-1.4c-0.1-0.4-0.5-0.5-1-0.5
		c-0.8,0-1.7,0.3-2.7,1c-1,0.7-1.9,1.4-2.8,2.2c-1.1,1-2.1,2.1-3.2,3.2c-1.1,1.1-2,2.2-2.9,3.4c-0.9,1.2-1.7,2.3-2.4,3.5
		c-0.7,1.2-1.3,2.3-1.7,3.4c-0.3,0.8-0.7,2-1.3,3.6c-0.6,1.6-1.2,3.4-1.9,5.4c-0.7,2-1.4,4.1-2.2,6.3c-0.8,2.2-1.5,4.3-2.2,6.3h-9.2
		L262,25.4c0.3-0.7,0.4-1.4,0.5-2c0.1-0.6,0.1-1,0-1.3l-5.5-0.7v-1h15.7L259,58.7l0.3,0.1c1.7-2.4,3.4-4.7,5.2-6.6
		c1.8-2,3.6-3.6,5.3-5c1.8-1.4,3.5-2.4,5.2-3.2c1.7-0.7,3.3-1.1,4.7-1.1c0.7,0,1.2,0.1,1.7,0.4c0.5,0.3,0.8,0.6,1.2,1
		c0.3,0.4,0.5,0.9,0.7,1.4c0.1,0.5,0.2,1,0.2,1.5c0,1-0.2,2.1-0.5,3.4c-0.3,1.3-0.8,2.7-1.3,4.2l-10.1,26.6h0.3
		c0.7-0.4,1.5-1,2.4-1.7c0.9-0.7,1.8-1.4,2.8-2.2c0.9-0.8,1.9-1.6,2.8-2.5c0.9-0.9,1.8-1.7,2.6-2.6l0.6,0.7
		c-0.3,0.5-0.9,1.2-1.7,2.1c-0.8,0.9-1.7,1.9-2.8,2.9c-1.1,1.1-2.2,2.1-3.4,3.2c-1.2,1.1-2.4,2.1-3.6,3c-1.2,0.9-2.3,1.7-3.3,2.2
		C267.3,87.1,266.4,87.4,265.7,87.4z"
      />
      <path
        fill="currentColor"
        d="M294.9,87.4c-1,0-2-0.1-3-0.4s-1.8-0.8-2.6-1.4c-0.8-0.7-1.4-1.5-1.9-2.6c-0.5-1.1-0.7-2.4-0.7-3.9
		c0-2.5,0.4-5.1,1.1-7.8c0.7-2.7,1.7-5.4,3-8.1c1.3-2.7,2.7-5.2,4.4-7.7c1.7-2.4,3.5-4.6,5.5-6.4c2-1.8,4-3.3,6.1-4.4
		c2.1-1.1,4.2-1.7,6.2-1.7c1.2,0,2.2,0.2,3.1,0.5c0.9,0.3,1.6,0.7,2.1,1.2c0.5,0.5,0.9,1.1,1.2,1.7c0.2,0.6,0.4,1.2,0.4,1.8
		c0,1.2-0.3,2.5-0.9,3.9c-0.6,1.4-1.7,2.8-3.4,4.2c-1.6,1.4-3.8,2.9-6.7,4.5c-2.8,1.6-6.4,3.1-10.9,4.7c-0.8,2.1-1.3,4.1-1.6,5.9
		c-0.3,1.8-0.5,3.3-0.5,4.5c0,2.1,0.4,3.6,1.2,4.6c0.8,0.9,2.1,1.4,3.8,1.4c1.1,0,2.2-0.2,3.3-0.7c1.1-0.5,2.2-1.1,3.3-1.9
		c1.1-0.8,2.1-1.8,3-3c1-1.1,1.9-2.4,2.7-3.7l1,0.6c-0.5,1-1,2-1.8,3c-0.7,1.1-1.6,2.1-2.6,3.2s-2.1,2.1-3.2,3.1
		c-1.2,1-2.4,1.8-3.7,2.6c-1.3,0.7-2.6,1.3-4,1.8C297.6,87.2,296.2,87.4,294.9,87.4z M298.5,64.2c2.8-1.1,5.2-2.3,7-3.5
		c1.9-1.2,3.3-2.5,4.4-3.8c1.1-1.3,1.9-2.6,2.3-4c0.5-1.3,0.7-2.7,0.7-4c0-1.4-0.2-2.4-0.7-3c-0.5-0.7-1-1-1.6-1
		c-0.8,0-1.7,0.6-2.8,1.7c-1.1,1.2-2.2,2.7-3.3,4.5c-1.1,1.8-2.2,3.9-3.3,6.2C300.2,59.6,299.3,61.9,298.5,64.2z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "the-gratis"
};
</script>
<style lang="scss" scoped></style>
